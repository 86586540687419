import { differenceInDays } from 'date-fns';

import { ParseGqlUtcDate } from '@/helpers/dates';
import { GetFundamentalColumn } from '@/helpers/fundamentals';
import { CalcPercentDiff } from '@/helpers/numbers';
import { InstrumentFundamentalType, SnapshotForHoldingsComponentType } from '@/queries/snapshot';
import { ProcessedAssetItem, ProcessedIndexItem } from '@/types/index';

type NormalizedIndexDataResult = {
    normalizedIndexData: Array<ProcessedIndexItem>;
    symbolList: Array<string>;
    performance: number | null;
};

type BenchmarkItemType = {
    level: string;
    symbol: string;
};

export const NormalizeIndexData = (
    data: Array<{
        asOfDate: string;
        netAssetValue: string;
        isBackcalculated: boolean;
        benchmarks?: Array<BenchmarkItemType>;
    }>,
    symbol: string
): NormalizedIndexDataResult => {
    const sortedData = data.slice().sort((a, b) => (a.asOfDate < b.asOfDate ? -1 : 1));

    // Sometimes, the dates for the index and for the benchmarks do not match. Loop through the list and make them match.
    // Also, turn price strings into numbers so we can math on them
    const symbolList: Array<string> = [symbol];
    const processedIndex: Array<ProcessedIndexItem> = sortedData
        .flatMap((item) => {
            const benchmarkObject: ProcessedIndexItem = {
                asOfDate: item.asOfDate,
                isBackcalculated: item.isBackcalculated,
                symbolValues: {},
            };

            let addToList = true;

            benchmarkObject.symbolValues[symbol] = parseFloat(item.netAssetValue);
            // the dataset might contain bench marks
            if (item.benchmarks && item.benchmarks.length) {
                item.benchmarks.forEach((bItem: BenchmarkItemType) => {
                    if (!symbolList.includes(bItem.symbol)) {
                        // add symbol to the list we of lines we are going to make
                        symbolList.push(bItem.symbol);
                    }

                    if (!bItem.level) {
                        // if one of the items doesn't have a value we don't want to add it to the list
                        addToList = false;
                    }
                    benchmarkObject.symbolValues[bItem.symbol] = parseFloat(bItem.level);
                });
            }

            if (!addToList) {
                // returning empty array which flatmap will ignore
                return [];
            }
            return [benchmarkObject];
        })
        .filter((item) => {
            // filter out items where the number of symbols doesn't equal the amount we expect
            return Object.keys(item.symbolValues).length === symbolList.length;
        });

    return {
        normalizedIndexData: processedIndex,
        performance:
            processedIndex.length > 0
                ? CalcPercentDiff(
                      processedIndex[0].symbolValues[symbol] as number,
                      processedIndex[processedIndex.length - 1].symbolValues[symbol] as number
                  )
                : null,
        symbolList,
    };
};

export const GetSnapshotClosestToDate = (date: Date, data: Array<ProcessedIndexItem>): ProcessedIndexItem => {
    const closest = data.reduce(function (prev, curr) {
        const currSnap = ParseGqlUtcDate(curr.asOfDate);
        const prevSnap = ParseGqlUtcDate(prev.asOfDate);

        return Math.abs(differenceInDays(currSnap, date)) < Math.abs(differenceInDays(prevSnap, date)) ? curr : prev;
    });

    return closest;
};

export const NormalizeAssetData = (assets: Array<SnapshotForHoldingsComponentType>): Array<ProcessedAssetItem> => {
    const sumAssetValues = (assets: Array<SnapshotForHoldingsComponentType>): number => {
        return assets.reduce((sum, asset) => {
            if (asset.instrument.isCash) {
                return sum;
            }
            const value = parseFloat(asset.value);
            return sum + (isNaN(value) ? 0 : Math.abs(value));
        }, 0);
    };
    const nonCashAbsNetAssetValue = sumAssetValues(assets);
    return assets
        .map(({ instrument, value }) => {
            const rawFundamentals = instrument.fundamentals;
            const numericValue = parseFloat(value);

            // before we push let's key the fundamentals to make sorting easier
            const keyedFundamentals = rawFundamentals?.reduce((object, fundamental) => {
                const getValue = (fundamental: InstrumentFundamentalType) => {
                    switch (fundamental.__typename) {
                        case 'InstrumentFundamentalDecimal':
                            return fundamental.decimalValue ? parseFloat(fundamental.decimalValue) : null;
                        default:
                            return fundamental.dateValue || fundamental.stringValue;
                    }
                };

                return { ...object, [fundamental.name]: getValue(fundamental) };
            }, {});

            return {
                companyName: instrument.companyName ?? '',
                exchange: instrument.exchange,
                fundamentals: keyedFundamentals ?? null,
                industry: instrument.latestFundamentals?.industryName || null,
                instrumentType: instrument.instrumentType,
                isCash: instrument.isCash ?? false,
                isShort: !isNaN(numericValue) && numericValue < 0,
                sector: instrument.latestFundamentals?.sectorName || null,
                symbol: instrument.currentSymbol ?? instrument.symbol ?? '',
                weight:
                    !isNaN(numericValue) && nonCashAbsNetAssetValue
                        ? Math.abs(numericValue) / nonCashAbsNetAssetValue
                        : null,
            };
        })
        .filter((instrument) => !instrument.isCash);
};

export const GenerateAndDownloadCSV = (
    fileName: string,
    assets: Array<ProcessedAssetItem>,
    displayedFundamentalsColumns: Array<string>
) => {
    //TODO(joe): make this more dynamic so the header of the file isn't hard coded
    const csvContent = generateCSVContent(assets, displayedFundamentalsColumns);

    const link = document.createElement('a');
    link.id = 'download-csv';
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvContent));
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    const ele: HTMLElement | null = document.querySelector('#download-csv');
    if (ele) {
        ele.click();
    }
};

const generateCSVContent = (assets: Array<ProcessedAssetItem>, displayedFundamentalsColumns: Array<string>): string => {
    const lines: Array<string> = [];
    lines.push(
        ['Name', 'Symbol', '% of Index']
            .concat(
                displayedFundamentalsColumns.map(
                    (columnName) => GetFundamentalColumn(columnName)?.csvLabel ?? columnName
                )
            )
            .join(',')
    );

    assets.forEach((item: ProcessedAssetItem) => {
        lines.push(
            [item.companyName, item.symbol, item.weight]
                .concat(displayedFundamentalsColumns.map((columnName) => `${item.fundamentals[columnName] || ''}`))
                .map((v) => `"${v}"`)
                .join(',')
        );
    });

    return lines.map((line) => `${line}\r\n`).join('');
};

export const GetWeightString = (weightType: string | undefined): string => {
    let weight = 'Custom';
    switch (weightType) {
        case 'EqualWeightedStrategy':
            weight = 'Equal';
            break;
        case 'CustomWeightedStrategy':
            weight = 'Custom';
            break;
        case 'MarketCapWeightedStrategy':
            weight = 'Market Cap';
            break;
        case 'RootMarketCapWeightedStrategy':
            weight = 'Sq. Root Market Cap';
            break;
        case 'PriceWeightedStrategy':
            weight = 'Price';
            break;
        case 'RulesBasedEqualWeightedStrategy':
            weight = 'Rules Based Equal';
            break;
        default:
            throw new Error('Unsupported weighting type ' + weightType);
    }
    return weight;
};
