import { parse, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const ParseGqlUtcDate = (dateString: string): Date => {
    // parse as if midnight UTC
    return parse(`${dateString} Z`, 'yyyy-MM-dd x', new Date());
};

export const ParseGqlOptionalUtcDateTime = (dateTimeString?: string | null): Date | null => {
    if (!dateTimeString) {
        return null;
    }

    return parseISO(dateTimeString);
};

// TODO: figure out how to use type for FormatOptions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormatUtcDate = (date: Date | number, format: string, options?: any) => {
    return formatInTimeZone(date, 'Etc/UTC', format, options);
};

export const convertYYYYMMDDToDate = (date: string) => {
    const [year, month, day] = date.split('-').map((s) => parseInt(s, 10));
    return new Date(year, month - 1, day);
};
