import ShareModal from '@/components/global/share-modal';
import { GetIndexSymbolPercentGraphEmbedPath, GetIndexSymbolPublicPath } from '@/helpers/paths';
import { THEMATIC_TWITTER_HANDLE } from '@/lib/constants';
import { type IndexTrackingProperties, ModalTypes } from '@/types/tracking';

type Props = {
    isOpen: boolean;
    closeFn: () => void;
    indexSymbol: string;
    trackingProperties?: IndexTrackingProperties;
};

const IndexShareModal = ({
    closeFn,
    isOpen,
    indexSymbol,
    trackingProperties = {} as IndexTrackingProperties,
}: Props) => {
    const link = GetIndexSymbolPublicPath(indexSymbol, { includeBaseUrl: true });
    const embed = GetIndexSymbolPercentGraphEmbedPath(indexSymbol, { includeBaseUrl: true });

    return (
        <ShareModal
            twitterShareString={`Check out this index from ${THEMATIC_TWITTER_HANDLE}`}
            gtagCategory="Index - Share Modal"
            trackingProperties={{
                ...trackingProperties,
                modalType: ModalTypes.INDEX_SHARE,
            }}
            {...{ closeFn, embed, isOpen, link }}
        />
    );
};
export default IndexShareModal;
